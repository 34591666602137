.fc-header-toolbar {
  padding: 0 24px;
  height: 75px;
  margin: 0 !important;
}

.fc-button-active {
  background: #EE475C !important;
  border-radius: 8px !important;
}

.fc-timegrid-event .fc-event-main {
  padding: 4px !important;
}

.fc-theme-standard td, .fc-theme-standard th {
  border-top: 0px solid #eee !important;
  border-bottom: 1px solid #dde1e2 !important;
}

.fc-timegrid-event .fc-event-main div {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fc-header-toolbar > :last-child {
  display: flex;
  justify-content: flex-end;
}

.fc-header-toolbar > :last-child button {
  border: 1px solid #E2E8E9 !important;
  border-radius: 6px !important;
  padding: 10px 15px !important;
}

.fc-header-toolbar > :last-child button:hover {
  background: #000 !important;
  color: #fff !important;
}

.fc .fc-button-primary {
  background-color: transparent;
  border-color: transparent !important;
  color: #000000;
  padding: 0;
  text-transform: capitalize;
}

.fc-button-group {
  gap: 0px !important;
}

.fc-toolbar-chunk {
  display: flex;
  flex: auto;
  align-items: center;
}

.fc-toolbar-chunk:first-child div button {
  display: inherit;
}

.fc .fc-button-primary:hover {
  background-color: transparent;
  border-color: transparent !important;
  color: #000000;
}

.fc .fc-button:hover .fc-button-primary:focus {
  background-color: transparent;
  border-color: transparent !important;
  color: #000000;
}

.fc-direction-ltr {
  height: 100%
}
.fc-theme-standard td, .fc-theme-standard th {
  border: 1px solid #eee;
}

.fc-daygrid-day-frame {
  background-color: #fff !important;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: #fff !important;
}

.fc-header-toolbar {
  height: 55px !important;
}

.fc-addAppointmentButton-button {
  background: #fff !important;
  border-radius: 8px !important;
  color: #000 !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  padding: 16px 12px !important;
  text-transform: capitalize;
  border: 1px solid #000 !important;
}

.fc-popover-header {
  display: none !important;
}

.fc-popover {
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.fc-popover-body {
  padding: 10px !important;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.fc-v-event .fc-event-main-frame {
  flex-direction: column-reverse !important;
}

.fc-event-title {
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

@media only screen and (max-width: 768px) {
  /* Styles for mobile screens */

  .fc-toolbar-title {
    font-size: 12px !important;
    line-height: 17px;
  }

  .fc .fc-button-primary {
    padding: 0 5px !important;
    font-size: 12px;
  }
}

/* 1440 and above screens */
@media only screen and (min-width: 1440px) {
  .fc-header-toolbar {
    height: 55px !important;
  }
}

.fc .fc-popover {
  z-index: 30 !important;
}

.rs-btn-primary {
  background-color: #3498ff !important;
  background-color: #3498ff !important;
}

a {
  text-decoration: none !important;
}

.rs-picker-menu {
  z-index: 60 !important;
}

.splice-time-input {
  padding: 13px !important;
}

.rs-picker-toggle-value {
  color: #000 !important;
}

.ant-btn-primary {
  background: #000 !important;
  color: #fff !important;
}

.ant-picker-focused {
  border: none !important;
}

.fc-today-button {
  color: #000 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  padding: 6px 10px !important;
  border-top: 1px solid #E2E8E9 !important;
  border-bottom: 1px solid #E2E8E9 !important;
  border-left: 1px solid #E2E8E9 !important;
  background: #F3F6F6 !important;
  box-shadow: 0px 4px 4px 0px #E3E3E3 !important;
  border-radius: 0px !important;
}

.fc-toolbar-title {
  font-weight: 700;
  font-size: 12px !important;
  line-height: 19px;
  color: #000;
  padding: 5px 17px !important;
  border-top: 1px solid #E2E8E9 !important;
  border-bottom: 1px solid #E2E8E9 !important;
  border-right: 1px solid #E2E8E9 !important;
  background: #fff !important;
  box-shadow: 0px 4px 4px 0px #E3E3E3 !important;
  margin-left: 0px !important;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr > .fc-toolbar-chunk:nth-child(1) > div:nth-child(3) {
  /* Your CSS styles for the third div element here */
  margin: 0px !important;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr > .fc-toolbar-chunk:nth-child(1) > div:nth-child(3) button {
  /* Your CSS styles for the third div element here */
  display: none !important;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr > .fc-toolbar-chunk:nth-child(1) > div:nth-child(2) > button:nth-child(2) {
  /* Your CSS styles for the third div element here */
  display: none !important;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr > .fc-toolbar-chunk:nth-child(2) .fc-button-group button {
  /* Your CSS styles for the third div element here */
  padding: 5px 20px !important;
  margin: 0 5px;
  border-radius: 4px !important;
}

.fc-timegrid-slots table tbody tr {
  height: 4em;
}

.fc-timegrid-axis-chunk table tbody tr {
  height: 4em;
}

.fc-scrollgrid .fc-scrollgrid-liquid thead {
  position: fixed;
}